@tailwind base;
@tailwind components;
@tailwind utilities;


.active {
  @apply bg-indigo-700 text-white;
}

.navPending {
  @apply flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold;
}
.navActive {
  @apply bg-gray-50 text-indigo-600;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  text-align: center;
}

[data-letters]:before {
  content:attr(data-letters);
  display:inline-block;
  font-size:1em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background:rgb(79 70 229 / var(--tw-bg-opacity)) !important;
  vertical-align:middle;
  margin-right:3px;
  margin-left:3px;
  color:white;
  }